
import { defineComponent, PropType } from 'vue'
import { IWaiter } from '@/types/waiter'
export default defineComponent ({
  name: 'VWaiterItem',
  props: {
    data: {
      type: Object as PropType<IWaiter>,
      required: true,
      default: () => {},
    },
  },
  emits: ['onHandler', 'emailReverce', 'waiterStatus'],
  data() {
    return {
      verif: '',
    }
  },
  computed: {
    statusWaiter(): string {
      const status = this.data.waiter_status
      return status === 'NOT INVITED'
        ? 'Ожидает приглашения'
        : status === 'INVITED'
        ? 'Приглашён в систему'
        : 'Официант верифицирован'
    },
  },
  methods: {
    onWainerInfo(): void {
      this.$emit('emailReverce', this.data.email)
      this.$emit('onHandler', this.data)
      this.$emit('waiterStatus', this.data.waiter_status)
    },
  },
})
