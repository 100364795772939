
import { defineComponent, PropType } from 'vue'
import { debounce } from 'lodash';
export default defineComponent ({
  name: 'VEmployeeSearch',
  props: {
    value: {
      type: String as PropType<string>,
      required: true,
      default: '',
    },
  },
  emits: ['update:modelValue', 'search', 'func'],
  data() {
    return {
      searchData: '',
    }
  },
  watch: {
    value() {
      if (this.value === '') this.searchData = ''
    },
  },
  methods: {
    onInput(event: Event | string) {
      if (typeof event === 'object' && event instanceof Event) {
        const target = event.target as HTMLInputElement; // Приведение типа к HTMLInputElement
        this.$emit('update:modelValue', target.value)
        this.searchData = target.value
      } else if (typeof event === 'string') {
        this.$emit('update:modelValue', event)
        this.searchData = event
      }
      const self = this;
      this.$emit('func', true)
      throttleMethodWrapper();

      function throttleMethodWrapper() {
        debounce(function () {
          self.$emit('search', self.searchData);
        }, 500)();
      }
    },
  },
})
