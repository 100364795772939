
import { defineComponent, PropType } from 'vue'
import vLightPreloader from '@/components/v-light-preloader.vue'
import { mapGetters, mapActions } from 'vuex'
import vWaiterItem from '@/components/v-waiter-item.vue'
import vPagination from '@/components/v-pagination.vue'
import vWaiterForm from '@/components/v-waiter-form.vue'
import VEmployeeSearch from '@/components/v-employee-search.vue'
import vue100vh from 'vue-100vh'
import { IWaiter } from '@/types/waiter'
export default defineComponent ({
  name: 'VSectionWaiter',
  components: {
    vWaiterItem,
    vPagination,
    vWaiterForm,
    VEmployeeSearch,
    vLightPreloader,
    vue100vh,
  },
  props: {
    restId: {
      type: Number as PropType<number>,
      default: null,
    },
    isTechSupport: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['scrollToTop'],
  data() {
    return {
      modal: null,
      waiterForm: false,
      waiterData: {} as IWaiter,
      test: 10,
      search: '',
      preloader: false,
      disabledBtn: false,
      textPreloader: 'Ищем совпадения',
      pageNumber: 1,
      emailReverce: '',
      waiterStatus: '',
    }
  },
  computed: {
    ...mapGetters('waiter', ['WAITERS']),
    ...mapGetters('user', ['USER_DATA', 'GET_AUTHENTIFICATED']),
  },

  async mounted() {
    await this.SEARCH_WAITERS({
      page: 1,
      limit: 10,
      search: '',
      token: this.GET_AUTHENTIFICATED,
      restId: this.restId,
    })
    setTimeout(() => {
      this.test = Object.keys(this.WAITERS.waiters).length
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },

  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('waiter', ['SEARCH_WAITERS', 'UPDATE_WAITERS_ADMIN', 'UPDATE_WAITERS_SUPORT']),

    async updateList() {
      this.textPreloader = 'Обновляем список официантов'
      this.disabledBtn = true
      this.togglePreloader(true)

      if (this.USER_DATA.role_user === 'manager') {
        await this.UPDATE_WAITERS_ADMIN(this.GET_AUTHENTIFICATED)
      } else {
        await this.UPDATE_WAITERS_SUPORT({
          token: this.GET_AUTHENTIFICATED,
          shop_id: this.restId
        })
      }

      await this.SEARCH_WAITERS({
        page: this.pageNumber,
        limit: 10,
        search: '',
        token: this.GET_AUTHENTIFICATED,
        restId: this.restId,
      })

      this.togglePreloader(false)
      this.disabledBtn = false
      this.textPreloader = 'Ищем совпадения'
    },

    async searchWaiters(searchData: string) {
      await this.SEARCH_WAITERS({
        page: '1',
        limit: 10,
        search: searchData,
        token: this.GET_AUTHENTIFICATED,
        restId: this.restId,
      })
      this.test = Object.keys(this.WAITERS.waiters).length
      this.togglePreloader(false)
    },
    async resetSearch() {
      this.togglePreloader(true)
      this.search = ''
      await this.SEARCH_WAITERS({
        page: '1',
        limit: 10,
        search: '',
        token: this.GET_AUTHENTIFICATED,
        restId: this.restId,
      })
      this.togglePreloader(false)
    },

    togglePreloader(val: boolean) {
      this.preloader = val
    },
    async onClickPaination(num: number) {
      this.TOGGLE_PRELOADER(true)
      this.pageNumber = num
      await this.SEARCH_WAITERS({
        page: num,
        limit: 10,
        search: this.search,
        token: this.GET_AUTHENTIFICATED,
        restId: this.restId,
      })

      this.TOGGLE_PRELOADER(false)
      this.test = Object.keys(this.WAITERS.waiters).length
    },
    toggleWaierForm(waiter: IWaiter) {
      this.waiterData = waiter
      this.waiterForm = !this.waiterForm
    },
    resetSearchField() {
      this.search = ''
    },
    toggleWaierFormElement() {

      this.$emit('scrollToTop')
      this.waiterForm = !this.waiterForm
      // this.TOGGLE_PRELOADER(true)
      setTimeout(async () => {
        await this.SEARCH_WAITERS({
          page: '1',
          limit: 10,
          search: this.search,
          token: this.GET_AUTHENTIFICATED,
          restId: this.restId,
        })
        this.TOGGLE_PRELOADER(false)
      }, 500)
    },

    onEmailReverce(e: string) {
      this.emailReverce = e
    },

    onWaiterStatus(e: string) {
      this.waiterStatus = e
    },
  },
})
