
import { defineComponent, PropType } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { Form } from 'vee-validate'
import vInput from '@/components/v-input.vue'
import vButton from '@/components/v-button.vue'
import vModalCancelInvitation from '@/components/modals/v-modal-cancel-invitation.vue'
import vModalUnlinkEmail from '@/components/modals/v-modal-unlink-email.vue'
import VButtonRed from '@/components/v-button-red.vue'
import { IWaiter } from '@/types/waiter'
export default defineComponent ({
  name: 'VWaiterForm',
  components: {
    VButtonRed,
    Form,
    vInput,
    vButton,
    vModalCancelInvitation,
    vModalUnlinkEmail,
  },
  props: {
    waiterData: {
      type: Object as PropType<IWaiter>,
      required: true,
    },
    emailReverce: {
      type: String as PropType<string>,
      required: true,
    },
    waiterStatus: {
      type: String as PropType<string>,
      required: true,
    },
    isTechSupport: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['toggleWaierFormElement', 'resetSearchField', 'updateItem'],
  data() {
    return {
      name: '',
      email: '',
      loginTelegram: '',
      idTelegram: '',
      id: '',
      display_name: '',
      confirm: false,
      confirmUntying: false,
      identicalMail: '',
      externalId: '',
      statusWaiterLocal: '',
      phone: '',
      card: '',
      bank: ''
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED', 'USER_DATA']),
    ...mapGetters('waiter', ['WAITERS_ERROR']),
    status(): string {
      let res = ''
      switch (this.statusWaiterLocal) {
        case 'INVITED':
          res = 'Приглашен в систему'
          break
        case 'VERIFIED':
          res = 'Официант верифицирован'
          break
        default:
          res = 'Ожидает приглашения'
      }
      return res
    },
    disabledSaveTelegramButton(): boolean {
      return (
        !this.idTelegram.length ||
        this.idTelegram.length < 5 ||
        !this.loginTelegram.length
      )
    },
  },
  watch: {
    email() {
      this.identicalMail = ''
    },
  },
  mounted() {
    this.name = this.waiterData.name
    this.display_name = this.waiterData.display_name ?? ''
    this.id = this.waiterData.external_id
    this.email = this.emailReverce ? this.emailReverce : ''
    this.statusWaiterLocal = this.waiterStatus

    this.externalId = this.waiterData.external_id || ''
    this.loginTelegram = this.waiterData.telegram_name || ''
    this.idTelegram = String(this.waiterData.telegram_id) || ''

    this.phone = this.waiterData?.phone ?? ''
    this.card = this.waiterData?.card ? this.waiterData.card.number_card : ''
    this.bank = this.waiterData?.card && this.waiterData.card.name_bank ? this.waiterData.card.name_bank : ''


  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('waiter', [
      'INVITATION',
      'UNDOINVITATION',
      'UNLINK_EMAIL',
      'SAVE_TELEGRAM_DATA',
    ]),
    async checkForm() {
      this.identicalMail = ''
      await this.TOGGLE_PRELOADER(true)
      await this.INVITATION({
        id: this.id,
        email: this.email,
        token: this.GET_AUTHENTIFICATED,
      })
      if (this.WAITERS_ERROR) {
        this.identicalMail = 'E-mail уже привязан к другому официанту'
      } else {
        this.statusWaiterLocal = 'INVITED'
      }
      this.TOGGLE_PRELOADER(false)
    },

    toggleConfirm() {
      this.confirm = !this.confirm
    },

    toggleConfirmUntying() {
      this.confirmUntying = !this.confirmUntying
    },

    async cancellationInvitation() {
      await this.UNDOINVITATION({
        external_id: this.id,
        token: this.GET_AUTHENTIFICATED,
      })
      // this.$emit("resetSearchField");
      // this.$emit("toggleWaierFormElement");
      this.statusWaiterLocal = 'NOT INVITED'
      this.email = ''
      this.confirm = !this.confirm
    },

    async untyingEmail() {
      await this.UNLINK_EMAIL({
        external_id: this.id,
        token: this.GET_AUTHENTIFICATED,
      })
      this.email = ''
      this.statusWaiterLocal = 'NOT INVITED'
      //this.$emit("resetSearchField");
      //this.$emit("toggleWaierFormElement");
      this.confirmUntying = !this.confirmUntying
    },

    async saveTelegramData() {
      await this.TOGGLE_PRELOADER(true)

      const data = {
        user_id: this.waiterData.user_id,
        telegram_nickname: this.loginTelegram,
        telegram_id: +this.idTelegram,
      }

      await this.SAVE_TELEGRAM_DATA({
        token: this.GET_AUTHENTIFICATED,
        ...data,
      })

      this.$emit('toggleWaierFormElement')

      // setTimeout(async () => {
      //   await this.TOGGLE_PRELOADER(false)
      // }, 500)
    },
  },
})
