import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eee489c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "waiter-status" }
const _hoisted_2 = { class: "section-profile__group" }
const _hoisted_3 = { class: "section-profile__group" }
const _hoisted_4 = {
  key: 0,
  class: "section-profile__subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vInput = _resolveComponent("vInput")!
  const _component_vButtonRed = _resolveComponent("vButtonRed")!
  const _component_vButton = _resolveComponent("vButton")!
  const _component_vModalCancelInvitation = _resolveComponent("vModalCancelInvitation")!
  const _component_vModalUnlinkEmail = _resolveComponent("vModalUnlinkEmail")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    class: "section-profile__form",
    onSubmit: _ctx.checkForm
  }, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(" Статус: "),
        _createElementVNode("span", null, _toDisplayString(_ctx.status), 1)
      ]),
      _createElementVNode("fieldset", _hoisted_2, [
        _createVNode(_component_vInput, {
          id: "name",
          modelValue: _ctx.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
          value: _ctx.name,
          placeholder: "Имя на кассе",
          rules: "required",
          disabled: true
        }, null, 8, ["modelValue", "value"]),
        _createVNode(_component_vInput, {
          id: "display_name",
          modelValue: _ctx.display_name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.display_name) = $event)),
          value: _ctx.display_name,
          placeholder: "Имя",
          disabled: true
        }, null, 8, ["modelValue", "value"])
      ]),
      _createElementVNode("fieldset", _hoisted_3, [
        (!_ctx.isTechSupport)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Пригласить официанта в систему "))
          : _createCommentVNode("", true),
        _createVNode(_component_vInput, {
          id: "email",
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
          value: _ctx.email,
          placeholder: "Почта",
          rule: "mail|max:254",
          error: _ctx.identicalMail,
          disabled: 
          _ctx.statusWaiterLocal === 'INVITED' ||
            _ctx.statusWaiterLocal === 'VERIFIED' ||
            _ctx.isTechSupport
        ,
          "empty-field-error": true
        }, null, 8, ["modelValue", "value", "error", "disabled"])
      ]),
      (_ctx.isTechSupport)
        ? (_openBlock(), _createBlock(_component_vInput, {
            key: 0,
            id: "loginTelegram",
            modelValue: _ctx.loginTelegram,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.loginTelegram) = $event)),
            value: _ctx.loginTelegram,
            placeholder: "Имя Telegram",
            rule: "max:254",
            disabled: false,
            "empty-field-error": true
          }, null, 8, ["modelValue", "value"]))
        : _createCommentVNode("", true),
      (_ctx.isTechSupport)
        ? (_openBlock(), _createBlock(_component_vInput, {
            key: 1,
            id: "idTelegram",
            modelValue: _ctx.idTelegram,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.idTelegram) = $event)),
            value: _ctx.idTelegram,
            type: "number",
            placeholder: "Id Telegram",
            disabled: false,
            "empty-field-error": true
          }, null, 8, ["modelValue", "value"]))
        : _createCommentVNode("", true),
      (_ctx.isTechSupport)
        ? (_openBlock(), _createBlock(_component_vInput, {
            key: 2,
            id: "externalId",
            modelValue: _ctx.externalId,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.externalId) = $event)),
            value: _ctx.externalId,
            type: "string",
            placeholder: "ID УС",
            disabled: true,
            "empty-field-error": true
          }, null, 8, ["modelValue", "value"]))
        : _createCommentVNode("", true),
      (_ctx.USER_DATA.role_user === 'support')
        ? (_openBlock(), _createBlock(_component_vInput, {
            key: 3,
            id: "phone",
            modelValue: _ctx.phone,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.phone) = $event)),
            value: _ctx.phone,
            placeholder: "Телефон",
            type: "string",
            disabled: true
          }, null, 8, ["modelValue", "value"]))
        : _createCommentVNode("", true),
      (_ctx.USER_DATA.role_user === 'support')
        ? (_openBlock(), _createBlock(_component_vInput, {
            key: 4,
            id: "card",
            modelValue: _ctx.card,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.card) = $event)),
            value: _ctx.card,
            placeholder: "Карта",
            type: "string",
            disabled: true
          }, null, 8, ["modelValue", "value"]))
        : _createCommentVNode("", true),
      (_ctx.USER_DATA.role_user === 'support')
        ? (_openBlock(), _createBlock(_component_vInput, {
            key: 5,
            id: "bank",
            modelValue: _ctx.bank,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.bank) = $event)),
            value: _ctx.bank,
            placeholder: "Банк",
            type: "string",
            disabled: true
          }, null, 8, ["modelValue", "value"]))
        : _createCommentVNode("", true),
      (_ctx.statusWaiterLocal === 'INVITED' && !_ctx.isTechSupport)
        ? (_openBlock(), _createBlock(_component_vButtonRed, {
            key: 6,
            onClick: _ctx.toggleConfirm
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Отменить приглашение ")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.statusWaiterLocal === 'VERIFIED' && !_ctx.isTechSupport)
        ? (_openBlock(), _createBlock(_component_vButtonRed, {
            key: 7,
            onClick: _ctx.toggleConfirmUntying
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Отвязать почту ")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (
        _ctx.statusWaiterLocal !== 'INVITED' &&
          _ctx.statusWaiterLocal !== 'VERIFIED' &&
          !_ctx.isTechSupport
      )
        ? (_openBlock(), _createBlock(_component_vButton, {
            key: 8,
            type: "submit",
            disabled: !_ctx.email.length || !!Object.keys(errors).length
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Пригласить официанта ")
            ]),
            _: 2
          }, 1032, ["disabled"]))
        : _createCommentVNode("", true),
      (_ctx.isTechSupport)
        ? (_openBlock(), _createBlock(_component_vButton, {
            key: 9,
            type: "submit",
            disabled: _ctx.disabledSaveTelegramButton || !!Object.keys(errors).length,
            onClick: _withModifiers(_ctx.saveTelegramData, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Сохранить ")
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.confirm)
        ? (_openBlock(), _createBlock(_component_vModalCancelInvitation, {
            key: 10,
            onToggleConfirm: _ctx.toggleConfirm,
            onCancellationInvitation: _ctx.cancellationInvitation
          }, null, 8, ["onToggleConfirm", "onCancellationInvitation"]))
        : _createCommentVNode("", true),
      (_ctx.confirmUntying)
        ? (_openBlock(), _createBlock(_component_vModalUnlinkEmail, {
            key: 11,
            onToggleConfirmUntying: _ctx.toggleConfirmUntying,
            onUntyingEmail: _ctx.untyingEmail
          }, null, 8, ["onToggleConfirmUntying", "onUntyingEmail"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}