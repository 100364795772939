
import { defineComponent, PropType } from 'vue'
import vCloseButton from '@/components/v-close-button.vue'
import vButton from '@/components/v-button.vue'
export default defineComponent ({
  name: 'VModalUnlinkEmail',
  components: {
    vCloseButton,
    vButton,
  },
  props: {
    summ: {
      type: Number as PropType<number>,
      require: true,
      default: 0,
    },
    token: {
      type: String as PropType<string>,
      required: true,
      default: '',
    },
  },
  emits: ['toggleConfirmUntying', 'untyingEmail', 'toggleModal'],
  computed: {},
  async mounted() {},
  methods: {
    payout(): void {
      this.$emit('toggleModal')
    },

    toggleConfirmUntying(): void {
      this.$emit('toggleConfirmUntying')
    },

    untyingEmail(): void {
      this.$emit('untyingEmail')
    },
  },
})
