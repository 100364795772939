
import { defineComponent, PropType } from 'vue'

export default defineComponent ({
  name: 'VPagination',
  props: {
    pages: {
      type: Number as PropType<number>,
      required: true,
    },
    page: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  emits: ['onHandler'],
  computed: {
    prevPage(): number {
      return this.page - 1
    },
    nextPage(): number {
      return this.page + 2
    },
    toCounter(): number[] {
      let arr = []
      for (let i = 0; i < this.pages; i++) {
        arr.push(i + 1)
      }
      let arrFiltered =
        this.pages !== this.page ? arr.splice(this.page - 1, 2) : arr.slice(-2)
      return arrFiltered
    },
  },
  methods: {
    onHandler(num: number): void {
      this.$emit('onHandler', num)
    },
  },
})
